:root {
  --bg-color: #eeeded;
  --accent: #003DA2;
  --accent2: #FF5823;
  --accent3: #f0de36;
  --darkaccent2: #a50f0e;
  --black: #000;
  --bs-gutter-x: 3rem;
}
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  //background-color: var(--bg-color);
  margin: 0;
  padding: 0;
}
.h1,
.h2 {
  font-size: 3rem;
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.75rem;
}

.h5 {
  font-size: 1.5rem;
}

.h6 {
  font-size: 1.25rem;
}
img {
  max-width: 100%;
}
p {
  font-size: 1rem;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.container {
  max-width: 100%;
  position: relative;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  //@media (min-width:1400px) {
  //max-width: 1320px;
  //}
  .row {
    display: flex;
    flex-wrap: wrap;
  }
}
.mainWrapper {
  header {
    padding: 0.5rem 0;
    @media (min-width: 992px) {
      padding: 1rem 0;
    }
    background-color: #fff;
    box-shadow: 0 1.125rem 2.25rem -1.188rem rgba(0,0,0,.4);
    position: relative;
    z-index: 2;
    .logo-wrapper {
      a {
        img {
          max-width: 2rem;
          @media (min-width: 992px) {
            max-width: 3rem;
          }
        }
      }
    }
  }
  .hero-section {
    z-index: 1;
    position: relative;
    background-color: rgb(245,251,255);
    //background-repeat: no-repeat;
    //background-size: cover;
    //background-position: 46%;
    //background-color: var(--bg-color);
    padding: 3rem 0;
    @media (min-width: 992px) {
      padding: 4rem 0;
    }
    .container {
      padding: 0 0.5rem;
      .row {
        height: 70vh;
        align-items: center;
        justify-content: center;
        .hero-container {
          //background-color: #fff;
          //padding: 1.5rem;
          //border-radius: 8px;
          width: 100%;
          flex: 0 0 auto;
          @media (min-width: 992px) {
            max-width: 46.875rem;
          }
          .headline-wrapper {
            text-align: center;
            h1 {
              color: var(--accent);
              line-height: 1.2;
              font-size: 1.5rem;
              font-weight: bold;
              margin-bottom: 1rem;
              @media (min-width: 1200px) {
                font-size: 2.25rem;
              }
            }
            h2 {
              font-size: 1.25rem;
              font-weight: 300;
              margin-bottom: 1rem;
              strong {
                color: var(--darkaccent2);
              }
              @media (min-width:768px) {
                font-size: 1.75rem;
                font-weight: 300;
                line-height: 2.25rem;
              }
            }
          }
        }
        .sub-hero-section {
          .hero-section-wrapper {
            .hero-section-wrapper-content {
              h2 {
                font-size: calc(1.325rem + .9vw);
                line-height: 1.2;
                margin-bottom: 1rem;
              }
              p {
                text-align: center;
                margin-bottom: 1rem;
                @media (min-width: 992px) {
                  font-size: 1.125rem;
                }
              }
            }
            .hero-sectiom-image {
              margin-bottom: 1rem;
            }
            .cta {
              a {
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                font-size: 1.5rem;
                font-weight: 700;
                background-color: var(--accent2);
                color: #fff;
                padding: 0.5rem 1rem;
                border-radius: 10px;
                img {
                  max-width: 40px;
                  margin-left: 0.5rem;
                }
                transition: all .2s ease-in-out;
                &:hover, &:focus {
                  background-color: var(--darkaccent2);
                }
              }
              span {
                margin-top: .5rem;
                display: block;
                text-align: center;
                @media (min-width: 992px) {
                  margin-top: 1rem;
                }
                font-size: 1rem;
              }
            }
            .image-section {
              max-width: 38.75rem;
              margin: 1rem auto;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              img {
                height: 3.125rem;
              }
            }
          }
        }
        .congrats {
          margin: 1rem auto;
          max-width: 580px;
          width: 100%;
          background-color: #fff;
          box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
          padding: 1.25rem;
          @media (min-width: 992px) {
              padding: 2rem;
          }
          border-radius: 18px;
          text-align: center;
          h2 {
            color: var(--accent);
            margin-bottom: 0.5rem;
            font-size: 1.4rem;
            line-height: 120%;
            @media (min-width: 992px) {
              font-size: 2rem;
            }
          }
          a {
            display: block;
            margin: 1rem 0;
            background-color: var(--accent2);
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            padding: 1rem;
            font-size: 1.5rem;
            border-radius: 8px;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            transition: all .2s ease-in-out;
            &:hover, &:focus {
              background-color: var(--darkaccent2); 
            }
          }
          p {
            span {
              color: #0d1282;
              font-weight: bold;
            }
          }
          img {
            margin-top: 1rem;
          }
        }
        .question-wrapper {
          flex: 0 0 auto;
          width: 100%;
          @media (min-width:992px) {
            background-color: #fff;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            padding: 3rem;
            border-radius: 10px;
            width: 60%;
          }
          .headline-wrapper {
            h1 {
              color: var(--black);
              font-size: 1.5rem;
              text-align: center;
              line-height: 1.2;
              //text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
              @media (min-width:1200px) {
                font-size: 1.75rem;
                text-shadow: none;
              }
            }
          }
          .question-item {
            .question {
              position: relative;
              background-color: #fff;
              box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
              padding: 1.5rem;
              text-align: center;
              @media (min-width: 992px) {
                  padding: 0;
                  background-color: transparent;
                  box-shadow: none;
                  margin: 0;
                  text-align: left;
              }
              border-radius: 18px;
              margin: 1rem auto;
              max-width: 580px;
              width: 100%;
              h2 {
                color: var(--accent);
                font-size: calc(1.3rem + .6vw);
                line-height: 1.2;
                font-weight: 700;
                @media (min-width:1200px) {
                  font-size: 2.25rem;
                }
              }
              a {
                text-align: center;
                display: block;
                margin: 1rem 0;
                background-color: var(--accent2);
                color: #fff;
                font-weight: bold;
                text-decoration: none;
                padding: 1rem;
                font-size: 1.5rem;
                border-radius: 8px;
                box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
              }
            }
            
          }
          h1 {
            color: #fff;
          }
        }
      }
    }
  }
  .logo-strip-section {
    background-color: white;
    padding-top: 2rem;
    @media (min-width: 992px) {
      padding-top: 4rem;
    }
    .logo-strip-headline {
      text-align: center;
      flex: 0 0 auto;
      width: 100%;
      h1 {
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 2px;
        color: var(--accent);
      }
    }
    .logo-strip {
      flex: 0 0 auto;
      width: 100%;
      margin: 1rem 0;
      .logoitem {
        max-width: 38.75rem;
        margin: 1rem auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 0.5rem;
        @media (min-width: 992px) {
          max-width: 50rem;
        }
        img {
          flex: 0 0 auto;
          width: 25%;
          margin-bottom: 0.25rem;
          @media (min-width: 992px) {
            width: 15%;
          }
        }
      }
    }
  }
  .testimonial {
    padding: 3rem 0;
    text-align: center;
    @media (min-width: 992px) {
      padding: 4rem 0;
    }
    img {
      max-width: 100%;
      @media (min-width: 992px) {
        max-width: 580px;
      }
    }
    .testimonial-headline {
      flex: 0 0 auto;
      width: 100%;
      h1 {
        color: var(--accent);
        margin-bottom: 1rem;
        line-height: 120%;
        font-size: 1.5rem;
      }
    }
    .testimonial-content-holder {
      flex: 0 0 auto;
      width: 100%;
      h3 {
        font-weight: 500;
      }
    }
    .testimonial-list {
      display: flex;
      margin-top: 2rem;
      flex-wrap: wrap;
      .testimonial-item {
        margin-bottom: 1rem;
        flex: 0 0 auto;
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 1rem;
        @media (min-width: 992px) {
          width: 33%;
        }
        .testimonial-inner {
          background-color: var(--bg-color);
          padding: 1.56rem;
          text-align: left;
          box-shadow: 0rem 0rem 1.25rem rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          .testimonial-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            .testimonial-left {
              img {
                max-width: 50%;
                border-radius: 10px;
              }
            }
            .testimonial-right {
              .star-rating {
                display: flex;
                img {
                  width: 150px;
                }
              }
            }
          }
          .testimonial-bottom {
            h3 {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
  .footer {
    background-color: var(--bg-color);
    padding: 4rem 0 6rem 0;
    .top-footer {
      p {
        font-size: .875rem;
        color: rgba($color: #000000, $alpha: 0.5);
      }
    }
    .bottom-footer {
      .footer-navigation {
        flex: 0 0 auto;
          width: 100%;
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        @media(min-width:992px) {
          justify-content: space-between;
        }
        .footer-copyright {
          flex: 0 0 auto;
          width: 100%;
          @media(min-width:992px) {
            width: auto;
          }
        }
        .footer-navigation-wrapper {
          flex: 0 0 auto;
          width: 100%;
          @media(min-width:992px) {
            width: auto;
          }
          ul {
            display: flex;
            justify-content: center;
            align-items: center;
            li {
              &:nth-child(1) {
                &::after {
                  content: '|';
                  margin: 0 1rem;
                }
              }
              a {
                text-decoration: none;
                color: var(--black);
              }
            }
          }
        }
      }
    }
  }
  .policy-wrapper {
    .policy-title {
      padding: 2rem 0;
      h1 {
        font-size: calc(1.375rem + 1.5vw);
        @media (min-width:1200px) {
          font-size: 2.5rem;;
        } 
      }
      flex: 0 0 auto;
      width: 100%;
      border-bottom: 1px solid gray;
      margin-bottom: 2rem;
    }
    .policy-paragraph {
      padding-bottom: 4rem;
      p {
        margin-bottom: .5rem;
      }
      h2 {
        font-size: calc(1.325rem + .9vw);
        margin: 1rem 0;
        @media(min-width:1200px) {
          font-size: 2rem;
        }
      }
      h3 {
        font-size: calc(1.3rem + .6vw);
        margin-bottom: 1rem;
        @media(min-width:1200px) {
          font-size: 1.75rem;
        }
      }
      h3 {
        font-size: calc(1.275rem + .3vw);
        @media(min-width:1200px) {
          font-size: 1.5rem;
        }
      }
      ul {
        list-style-type: disc;
        margin-bottom: .5rem;
      }
    }
  }
}
